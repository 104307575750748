import { ICONBUTTON, NAVBUTTON } from "./Button.styles";
import { IButtonProps, IIconButtonProps, INavButtonProps } from "./Button.interface";
import { useTheme } from "styled-components";
import { BUTTONGRADIENT } from "../../styles/base.styles";
import { Loader } from "../Loader/Loader";

const NavButton = ({ action, text, notification, icon: Icon }: INavButtonProps) => {
  const theme = useTheme() as any;

  return (
    <NAVBUTTON onClick={ action } className={ notification ? notification : "" }>
      { notification && <span className="notification"/> }
      <Icon color={ theme.gray }/>
      { text }
    </NAVBUTTON>
  )
}

const IconButton = ({ action, icon, buttonSize }: IIconButtonProps) => {
  return (
    <ICONBUTTON onClick={ action } buttonSize={ buttonSize }>
      { icon }
    </ICONBUTTON>
  )
}

const Button = ({ action, text, type, loading, disabled }: IButtonProps): JSX.Element => {
  return (
    <BUTTONGRADIENT>
      <button onClick={ action } type={ type } disabled={ disabled }>{ loading ? <Loader/> : text }</button>
    </BUTTONGRADIENT>
  )
}

export {
  NavButton,
  IconButton,
  Button
}