import { CircleCheckIcon, ExclamationIcon } from "../Icon/Icon";
import { appColorsConfig } from "../../styles/themeConfig";
import { WARNING } from "./Warning.styles";
import { IWarningProps } from "./Warning.interface";

const Warning = ({ message, title, isSuccess, noAttention }: IWarningProps) => {
  return (
    <WARNING className={ isSuccess ? "success" : "warn" }>
      {
        isSuccess
          ? <CircleCheckIcon color={ appColorsConfig.templatea.valid }/>
          : <ExclamationIcon color={ appColorsConfig.templatea.warning }/>
      }
      <div>
        { title && <h3>{ title }</h3> }
        { message }
      </div>
    </WARNING>
  )
}

export {
  Warning
}