import React from "react";
import { appColorsConfig, GlobalStyleConfig } from "./styles/themeConfig";
import { ThemeProvider } from "styled-components";
import { StoreProvider } from "./context/Store/StoreContext";
import { Router } from "./router/Router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider theme={ appColorsConfig.templatea }>
      <GlobalStyleConfig/>
      <StoreProvider>
        <BrowserRouter>
          <Router/>
          <ToastContainer limit={ 1 }/>
        </BrowserRouter>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
