import React from "react";
import { DefaultTheme } from "styled-components";
import { FieldError, FieldValues, SubmitHandler } from "react-hook-form/dist/types";
import { UseFormReturn } from "react-hook-form/dist/types/form";

export interface IInputProps {
  name: string;
  label?: string;
  children?: React.ReactNode;
  type?: EInputType;
  isDecimal?: boolean;
  required?: boolean;
  isUpperCase?: boolean;
  format?: string;
  onFocus?: Function;
  options?: Array<ISelectOption>;
  switchLabel?: string;
  noValidate?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  selectFn?: Function;
  radioValue?: string | number;
  future?: boolean;
  isHide?: boolean;
  maxValue?: number;
  minValue?: number;
  maxLength?: number;
  minLength?: number;
  maxDate?: Date | null;
  accepts?: Array<'image/*' | '.pdf' | 'video/*' | '.docx' | '.xlsx' | '.csv'>;
  minDate?: Date | null
  multi?: boolean;
  placeholder?: string;
  field?: any;
}

export interface ISelectProps {
  field?: any;
  options: Array<ISelectOption> | [];
  selectFn?: Function;
  commonProps?: {
    readOnly: boolean;
    className: string;
    type: string;
    "aria-label": string;
    "data-testid": string,
    placeholder?: string;
  };
}

export interface ISwitchProps {
  field?: any;
  switchLabel?: string;
  commonProps?: {
    readOnly: boolean;
    className: string;
    type: string;
    "aria-label": string;
    "data-testid": string
  };
}

export interface IRadioProps {
  field?: any;
  children?: React.ReactNode;
  commonProps?: {
    readOnly: boolean;
    className: string;
    type: string;
    "aria-label": string;
    "data-testid": string
  };
}

export interface ILicensePlateProps {
  field?: any;
  commonProps?: {
    readOnly: boolean;
    className: string;
    type: string;
    "aria-label": string;
    "data-testid": string
  };
}

export interface IDocumentProps extends ILicensePlateProps {
}

export interface IDatePickerProps {
  field: any;
  minDate: Date | null;
  maxDate: Date | null;
  commonProps?: {
    readOnly: boolean;
    className: string;
    type: string;
    "aria-label": string;
    "data-testid": string
  };
}

export enum EInputType {
  Text = "text",
  Number = "number",
  Password = "password",
  Email = "email",
  Select = "select",
  Switcher = "switcher",
  File = "file",
  Radio = "radio",
  Checkbox = "checkbox",
  Textarea = "textarea",
  Mask = "mask",
  Document = "document",
  Date = "date",
  LicensePlate = "licensePlate",
  Currency = "currency",
}

export interface IErrorHandlerProps {
  error?: FieldError | undefined
}

export interface ILabelHandlerProps extends IErrorHandlerProps {
  required?: boolean,
  label?: string,
  name: string
  type: EInputType | undefined,
  readOnly: boolean | undefined,
}

export interface ISelectOption {
  id: string,
  name: string
}

export interface IInputSC {
  theme: DefaultTheme;
  inputType?: string;
  isHide?: boolean;
}

export interface ISwitcherSC {
  theme: DefaultTheme;
  active: boolean;
}

export interface IDatepickerSC extends IInputSC {
  future?: boolean;
}

export interface IInputContextProps {
  children?: React.ReactNode;
  onValid: SubmitHandler<FieldValues>;
  methods: UseFormReturn<FieldValues>;
}

