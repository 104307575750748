import { CONTAINER, TITLE } from "../../styles/base.styles";
import { useEffect } from "react";
import { Loader } from "../../components/Loader/Loader";
import { useTheme } from "styled-components";
import { CONFIRMEMAIL } from "./ConfirmEmail.styles";
import { useNavigate } from "react-router-dom";
import { putConfirEmailService } from "../../services/auth.service";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";
import { ERoutes } from "../../router/Router.interface";

const ConfirmEmail = () => {
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[2];

  useEffect(() => {
    if (token) {
      putConfirEmailService(token)
        .then(() => {
          notifyUtil(ENotificationTypes.Success, "Email confirmado com sucesso!");
        })
        .catch(() => {
          notifyUtil(ENotificationTypes.Error, "Não foi possível confirmar o email!");
        })
        .finally(() => setTimeout(() => navigate(ERoutes.Login), 3000))
    }
  }, [ token ]);


  return (
    <CONFIRMEMAIL>
      <CONTAINER>
        <TITLE>Confirmação de email.</TITLE>
        <Loader color={ theme.companySecondary }/>
      </CONTAINER>
    </CONFIRMEMAIL>
  )
}

export {
  ConfirmEmail
}