const toCapitalized = (text: string): string => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
}

const currencyToFloat = (amount: string): number => {
  return parseFloat(amount.replace(/\./g, "").replace(",", ".").replace("R$", "").trim());
};

const toBRCurrency = (value: any, toFixed: number = 2, withOperator: boolean = true) => {
  if (Number(value) === 0) return "R$ 0,00";
  if (!withOperator) {
    return `R$ ${ parseFloat(value).toLocaleString('pt-BR', {
      maximumFractionDigits: toFixed,
      minimumFractionDigits: toFixed
    }) }`
  }
  return `<span class="${ Number(value) < 0 ? "invalid" : "valid" }">R$ ${ Number(value) >= 0 ? "+" : "" }${ parseFloat(value).toLocaleString('pt-BR', {
    maximumFractionDigits: toFixed,
    minimumFractionDigits: toFixed
  }) }</span>`
}

const removeBlankSpace = (text: string): string => {
  if (text) {
    return text.replace(/\s/g, "");
  }
  return "";
}

const toDefaultInputDate = (date: string): string => {
  return new Date(date).toISOString().split('T')[0]
}

const removeSpecialCharacters = (text: string): string => {
  if (text) {
    return text.replace(/[^a-zA-Z0-9]/g, "");
  }
  return "";
}

const toSerialNumber = (text: string): string => {
  return `${ text.substring(0, 4) } ${ text.substring(4, 8) } ${ text.substring(8, 12) } ${ text.substring(12, 16) }`;
}

const toLicensePlateMask = (licensePlate: string): string => {
  if (licensePlate) {
    return `${ licensePlate.substring(0, 3) }-${ licensePlate.substring(3) }`.toUpperCase()
  }
  return "";
}

const removeCharactersFromEnd = (text: string, charQuantity: number): string => {
  if (text) {
    return text.substring(0, text.length - charQuantity);
  }
  return "";
}


export {
  toCapitalized as toCapitalizedUtil,
  removeBlankSpace as removeBlankSpaceUtil,
  toBRCurrency as toBRCurrencyUtil,
  toDefaultInputDate as toDefaultInputDateUtil,
  currencyToFloat as currencyToFloatUtil,
  removeSpecialCharacters as removeSpecialCharactersUtil,
  toSerialNumber as toSerialNumberUtil,
  toLicensePlateMask as toLicensePlateMaskUtil,
  removeCharactersFromEnd as removeCharactersFromEndUtil
}