import styled from "styled-components";
import { CONTAINER, SUBTITLE } from "../../styles/base.styles";

const CONFIRMEMAIL = styled.section`
  padding: 0 8px;

  > ${ CONTAINER } {
    > ${ SUBTITLE } {
      margin-bottom: 48px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;
    }
  }
`;

export {
  CONFIRMEMAIL,
}