import React from "react";
import { IInputContextProps } from "./Input.interface";
import { FormProvider } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types/form";
import { isTestUtil } from "../../utils/env.util";

export const InputContext = React.createContext<UseFormReturn | {}>({});

export const InputProvider = ({ methods, children, onValid }: IInputContextProps) => {
  const {
    watch,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState,
    resetField,
    reset,
    handleSubmit,
    unregister,
    control,
    register,
    setFocus
  } = methods;

  const onInvalid = (data: any) => {
    if (isTestUtil) {
      return
    }
  }

  return (
    <InputContext.Provider
      value={ {
        watch,
        getValues,
        getFieldState,
        setError,
        clearErrors,
        setValue,
        trigger,
        formState,
        resetField,
        reset,
        handleSubmit,
        unregister,
        control,
        register,
        setFocus
      } }>
      <FormProvider { ...methods }>
        <form role={ `form` } onSubmit={ handleSubmit(onValid, onInvalid) }>
          { children }
        </form>
      </FormProvider>
    </InputContext.Provider>
  )
}