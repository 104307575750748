import { EBridgeMessage, EMobbyAppEvents, ENotificationTypes, IGeolocation } from "../types/global.interface";
import { appSettingsUtil, isDevUtil } from "../utils/env.util";
import { postJWETokenService } from "./auth.service";
import { notifyUtil } from "../utils/toast.util";
import { IPostJWEToken } from "../pages/Register/Register.interface";
import { getStorageItemUtil } from "../utils/storage.util";
import { emitEventUtil } from "../utils/event.util";

const jsMessageInterceptor = (e: MessageEvent): void => {

  const { origin } = e;
  if (origin === appSettingsUtil.mobbyUrl || isDevUtil) {

    if (typeof e.data === "string" && e.data) {
      const data = JSON.parse(e.data);

      switch (data.type) {
        case EBridgeMessage.Authorize:
          authorizeMessageHandler(data);
          break;

        case EBridgeMessage.Checkout:
          checkoutMessageHandler(data);
          break;

        case EBridgeMessage.GrantAuthorization:
          grantAuthorizeMessageHandler(data);
          break;

        case EBridgeMessage.SetAppTitle:
          console.log({ data })
          emitEventUtil(EMobbyAppEvents.SetTitle, data.data.text);
          break;

        default:
          break;
      }
    }
  }
}

const authorizeMessageHandler = async (data: any) => {
  console.log("chegou no authorizeMessageHandler");
  const iframe = document.getElementById("mobby-hub-iframe") as HTMLIFrameElement;
  try {
    let position: IGeolocation = { latitude: 0, longitude: 0 }
    navigator.geolocation.getCurrentPosition((newPosition) => {
      position = { latitude: newPosition.coords.latitude, longitude: newPosition.coords.longitude }
    })

    const objToSend: IPostJWEToken = {
      token: JSON.parse(getStorageItemUtil("accessToken")),
      ...position
    }

    console.log({ objToSend });

    const response = await postJWETokenService(objToSend);
    iframe.contentWindow!.postMessage('{"messageId":"' + data.messageId + '","isSuccessfull":true,"args":[{"acessToken":"' + response.data.accessToken + '"}]}', '*');
  } catch (error) {
    console.log({ error })
    notifyUtil(ENotificationTypes.Error, "Não foi possível autorizar o acesso ao Mobby Hub");
    // window.removeEventListener("message", jsMessageInterceptor);
  }
}

const checkoutMessageHandler = (data: any) => {
  // iframe.contentWindow!.postMessage('{"messageId":"' + e.data.messageId + '","isSuccessfull":true,"args":[{"orderId":"' + +'","authorizationCode":"' + +'"}]}', '*');
}

const grantAuthorizeMessageHandler = (data: any) => {
  // iframe?.contentWindow?.postMessage('{"messageId":"' + e.data.messageId + '","isSuccessfull":true,"args":[{"requestId":"' + +'","authorizationCode":"' + +'"}]}', '*');
}

export {
  jsMessageInterceptor
}