import React, { createContext, Reducer, useEffect, useReducer, useState } from "react";
import { EStoreActionTypes, IStoreAction, IStoreData, IStoreProviderProps } from "./Store.interface";
import { notifyUtil } from "../../utils/toast.util";
import { getStateFromStorageUtil, getStorageItemUtil } from "../../utils/storage.util";
import { ENotificationTypes } from "../../types/global.interface";
import { Loader } from "../../components/Loader/Loader";
import { useTheme } from "styled-components";
import { isProdUtil } from "../../utils/env.util";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const StoreContext = createContext<any>(null);

const storeReducer: Reducer<IStoreData, IStoreAction> = (state: IStoreData, action: IStoreAction) => {
  switch (action.type) {

    case EStoreActionTypes.SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload }

    default:
      return state;
  }
}

const initialState = {
  isAuthenticated: false,
}

const StoreProvider = ({ children }: IStoreProviderProps) => {
  const theme = useTheme() as any;
  const [ store, setStore ] = useReducer<Reducer<IStoreData, IStoreAction>>(storeReducer, initialState);
  const [ isReady, setIsReady ] = useState(false);
  // useDevContextPersist(store, "MobbyAppReact");

  //SETUP DO CONTEXT DO MÓDULO
  const setupContext = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await setupFirebase();

        const token = getStorageItemUtil("accessToken");

        getStateFromStorageUtil("MobbyAppReact", setStore);

        if (token) setStore({ type: EStoreActionTypes.SET_IS_AUTHENTICATED, payload: true });

        resolve()

      } catch (err) {
        reject(err)
      }
    })
  }

  const setupFirebase = () => {
    return new Promise<void>((resolve, reject) => {
      if (!isProdUtil) return resolve();

      try {
        const firebaseConfig = {
          apiKey: "AIzaSyByyA5UlDP7_n8fOwOhFwHf3w5SSeTmvY8",
          authDomain: "mobbyapp-13b26.firebaseapp.com",
          projectId: "mobbyapp-13b26",
          storageBucket: "mobbyapp-13b26.appspot.com",
          messagingSenderId: "385312290841",
          appId: "1:385312290841:web:d760160c8937ed1153d288",
          measurementId: "G-356JFXFY6Z"
        };

        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);

        resolve();
      } catch (err) {
        reject(err)
      }

    })
  }

  useEffect(() => {
    setupContext()
      .then(res => {
        setIsReady(true);
        console.log("[MobbyApp] module is ready!");
      })
      .catch(err => {
        setIsReady(true);
        console.log({ err })
        notifyUtil(ENotificationTypes.Error, `[Gaea] Erro ao carregar o contexto do módulo.`)
      })
  }, [])

  if (!isReady) return (<Loader color={ theme.companySecondary } isFullSize text={ "Carregando Mobby App" }/>)

  return (
    <StoreContext.Provider value={ { store, setStore } }>
      { children }
    </StoreContext.Provider>
  )
}

export {
  StoreContext,
  StoreProvider
}