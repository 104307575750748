import styled from "styled-components";


const WARNING = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 24px 0;
  width: 100%;

  > div {
    width: calc(100% - (16px + 12px));

    > h3 {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 16px;
      color: ${ ({ theme }) => theme.darkgray };
    }

    > p {
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: ${ ({ theme }) => theme.darkgray };

      > * {
        font-size: 1.2rem;
      }
    }
  }

  > svg {
    position: relative;
    top: -1px;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  &.warn {
    > svg {
      width: 20px;
      height: 20px;
    }
  }
`

export { WARNING }