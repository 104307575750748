import { CONTAINER, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import { Button } from "../../components/Button/Button";
import React, { useContext, useState } from "react";
import { StoreContext } from "../../context/Store/StoreContext";
import { Navigate, useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";
import { REGISTER } from "./Register.styles";
import { postRegisterService } from "../../services/auth.service";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";
import { IPostRegister } from "./Register.interface";

const Register = (): JSX.Element => {
  const methods = useForm();
  const [ loading, setLoading ] = useState(false);
  const [ isCreatedUser, setIsCreatedUser ] = useState(false);
  const { setStore, store } = useContext(StoreContext);
  const navigate = useNavigate();

  const register = (data: any) => {
    setLoading(true);

    delete data.terms;
    const objToSend: IPostRegister = { ...data, sendConfirmationEmail: true }

    postRegisterService(objToSend)
      .then(res => {
        notifyUtil(ENotificationTypes.Success, "Cadastro realizado com sucesso!");
        setIsCreatedUser(true);
      })
      .finally(() => setLoading(false));
  }

  if (store.isAuthenticated) return <Navigate to={ ERoutes.Mobby } replace={ true }/>

  if (isCreatedUser) return <RegisterSuccess/>;

  return (
    <REGISTER>
      <CONTAINER>
        <div className={ "register__content" }>
          <TITLE>Dados pessoais</TITLE>
          <SUBTITLE>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequatur dignissimos
            doloribus eius, fugit incidunt iste quasi quisquam repellat tempora!</SUBTITLE>
          <InputProvider onValid={ register } methods={ methods }>
            <Input name={ "email" } type={ EInputType.Email } label={ "Email" } required/>
            <Input name={ "username" } type={ EInputType.Text } label={ "Username" } required/>
            <Input name={ "password" } type={ EInputType.Password } label={ "Senha" } required/>
            <Input name={ "firstName" } type={ EInputType.Text } label={ "Nome" } required/>
            <Input name={ "lastName" } type={ EInputType.Text } label={ "Sobrenome" } required/>
            <Input name={ "terms" } type={ EInputType.Checkbox } label={ "Lembrar meus dados" }>
              <label htmlFor={ "terms" }>
                Estou de acordo com o <a href="#">Termo e Condições</a> e as <a href="#">Políticas de Privacidade</a>
              </label>
            </Input>
            <Button type={ "submit" } text={ "Cadastrar" } disabled={ loading } loading={ loading }/>
          </InputProvider>
        </div>
      </CONTAINER>
    </REGISTER>
  )
}


const RegisterSuccess = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <REGISTER>
      <CONTAINER>
        <div className={ "register__content" }>
          <TITLE>Cadastro concluído</TITLE>
          <SUBTITLE>Um email de confirmação foi enviado para o seu endereço de email.</SUBTITLE>
          <Button text={ "Ir para login" } type={ "button" } action={ () => navigate(ERoutes.Login) }/>
        </div>
      </CONTAINER>
    </REGISTER>
  )
}

export {
  Register
}