import { CONTAINER, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import { Button } from "../../components/Button/Button";
import { LOGIN } from "./Login.styles";
import { useContext, useState } from "react";
import { StoreContext } from "../../context/Store/StoreContext";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";
import { postLoginService } from "../../services/auth.service";
import { setStorageItemUtil } from "../../utils/storage.util";
import { EStoreActionTypes } from "../../context/Store/Store.interface";

const Login = (): JSX.Element => {
  const methods = useForm();
  const [ loading, setLoading ] = useState(false);
  const { setStore, store } = useContext(StoreContext);
  const navigate = useNavigate();

  const handleAuthenticate = (data: any) => {
    setLoading(true);

    postLoginService(data)
      .then(res => {
        setStorageItemUtil("accessToken", res.data.access_token);
        setStore({ type: EStoreActionTypes.SET_IS_AUTHENTICATED, payload: true });
        navigate(ERoutes.Mobby);
      })
      .catch(() => setLoading(false))
  }

  if (store.isAuthenticated) return <Navigate to={ ERoutes.Mobby } replace={ true }/>

  return (
    <LOGIN>
      <CONTAINER>
        <div className={ "login__content" }>
          <TITLE>Acesse sua conta</TITLE>
          <SUBTITLE>Informe seus dados para acessar sua conta</SUBTITLE>
          <InputProvider onValid={ handleAuthenticate } methods={ methods }>
            <Input name={ "username" } type={ EInputType.Text } placeholder={ "Email" } required/>
            <Input name={ "password" } type={ EInputType.Password } placeholder={ "Senha" } required/>
            <Input name={ "rememberMe" } type={ EInputType.Checkbox } label={ "Lembrar meus dados" }>
              <label htmlFor={ "rememberMe" }>
                Lembrar meus dados
              </label>
              <Link to={ ERoutes.ForgotPassword }>Esqueci minha senha</Link>
            </Input>
            <Button type={ "submit" } text={ "Entrar" } disabled={ loading } loading={ loading }/>
          </InputProvider>
          <p className={ "login__register-text" }>Ainda não criou sua conta? <Link to={ ERoutes.Register }>Clique aqui
            para criar</Link></p>
        </div>
      </CONTAINER>
    </LOGIN>
  )
}

export {
  Login
}