import { CONTAINER, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import { CHANGEPASSWORDBYFORGOT } from "./Login.styles";
import { useContext, useState } from "react";
import { StoreContext } from "../../context/Store/StoreContext";
import { Navigate, useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";
import { putUpdatePasswordService } from "../../services/auth.service";
import { Button } from "../../components/Button/Button";
import { IPutUpdatePassword } from "./Login.interface";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";

const ChangePasswordByForgot = (): JSX.Element => {
  const methods = useForm();
  const [ loading, setLoading ] = useState(false);
  const { setStore, store } = useContext(StoreContext);
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[3];

  const resetPassword = (data: any) => {
    setLoading(true);

    if (!token) {
      notifyUtil(ENotificationTypes.Error, "Token inválido");
      return setLoading(false);
    }

    if (data.password !== data.confirmPassword) {
      notifyUtil(ENotificationTypes.Error, "As senhas não conferem");
      return setLoading(false);
    }

    const objToSend: IPutUpdatePassword = {
      newPassword: data.password,
      token: token || ""
    }

    putUpdatePasswordService(objToSend)
      .then(res => {
        notifyUtil(ENotificationTypes.Success, "Senha alterada com sucesso!");
        setTimeout(() => {
          navigate(ERoutes.Login);
        }, 300)
      })
      .catch(() => setLoading(false))
  }

  if (store.isAuthenticated) return <Navigate to={ ERoutes.Mobby } replace={ true }/>

  return (
    <CHANGEPASSWORDBYFORGOT>
      <CONTAINER>
        <div className={ "change-password-by-forgot__content" }>
          <TITLE>Nova senha</TITLE>
          <SUBTITLE>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aspernatur distinctio nobis, omnis
            quas voluptate!</SUBTITLE>
          <InputProvider onValid={ resetPassword } methods={ methods }>
            <Input name={ "password" } type={ EInputType.Password } placeholder={ "Senha" } required/>
            <Input name={ "confirmPassword" } type={ EInputType.Password } placeholder={ "Confirmar Senha" } required/>
            <Button type={ "submit" } text={ "Alterar Senha" } disabled={ loading } loading={ loading }/>
          </InputProvider>
        </div>
      </CONTAINER>
    </CHANGEPASSWORDBYFORGOT>
  )
}

export {
  ChangePasswordByForgot
}