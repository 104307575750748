declare global {
  interface Window {
    MSH: any;
    MobbyPlayground: any;
    ReactNativeWebView: any;
  }
}

export interface IDictionary<T> {
  [Key: string]: T;
}

export interface IStoreAction {
  type: string;
  payload: any;
}

export interface IValidationForm {
  errorMessage?: string;

  validation(value: any): boolean;
}

export interface IValidationFormDictionary {
  [key: string]: IValidationForm
}

export interface IGeolocation {
  latitude: number;
  longitude: number;
}

export enum EMobbyAppEvents {
  AuthenticationIsReady = "@greenpass/mobby-app-react/is-ready",
  SetTitle = "@greenpass/mobby-app-react/set-title",
}

export enum ENotificationTypes {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export enum EBridgeMessage {
  Checkout = "Checkout",
  GrantAuthorization = "GrantAuthorization",
  Authorize = "Authorize",
  SetAppTitle = "SetTitle",
  ReadQrCode = "ReadQrCode",
}