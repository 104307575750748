import { CONTAINER, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import { Button } from "../../components/Button/Button";
import { FORGOTPASSWORD } from "./Login.styles";
import { useContext, useState } from "react";
import { StoreContext } from "../../context/Store/StoreContext";
import { Navigate, useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";
import { putForgotPasswordService } from "../../services/auth.service";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";

const ForgotPassword = (): JSX.Element => {
  const methods = useForm();
  const [ loading, setLoading ] = useState(false);
  const { setStore, store } = useContext(StoreContext);
  const navigate = useNavigate();
  const [ isEmailSent, setIsEmailSent ] = useState(false);

  const handleForgotPassword = (data: any) => {
    setLoading(true);

    putForgotPasswordService(data.email)
      .then(res => {
        notifyUtil(ENotificationTypes.Success, "Email enviado com sucesso!");
        setIsEmailSent(true);
      })
      .catch(() => setLoading(false))
  }

  if (store.isAuthenticated) return <Navigate to={ ERoutes.Mobby } replace={ true }/>

  if (isEmailSent) return <ForgotPasswordSuccess/>

  return (
    <FORGOTPASSWORD>
      <CONTAINER>
        <div className={ "forgot-password__content" }>
          <TITLE>Recuperação de senha</TITLE>
          <SUBTITLE>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, voluptatem.</SUBTITLE>
          <InputProvider onValid={ handleForgotPassword } methods={ methods }>
            <Input name={ "email" } type={ EInputType.Text } placeholder={ "Email" } required/>
            <Button type={ "submit" } text={ "Recuperar Senha" } disabled={ loading } loading={ loading }/>
          </InputProvider>
        </div>
      </CONTAINER>
    </FORGOTPASSWORD>
  )
}

const ForgotPasswordSuccess = (): JSX.Element => {
  return (
    <FORGOTPASSWORD>
      <CONTAINER>
        <div className={ "forgot-password__content" }>
          <TITLE>Recuperação de senha</TITLE>
          <SUBTITLE>Um email com as instruções foi enviado para o seu endereço.</SUBTITLE>
        </div>
      </CONTAINER>
    </FORGOTPASSWORD>
  )
}

export {
  ForgotPassword
}