import styled from "styled-components";
import { IIconButtonSCProps } from "./Button.interface";


const NAVBUTTON = styled.button`
  padding: 6px;
  position: relative;
  min-width: 96px;
  height: 94px;
  border-radius: 8px;
  border: solid 1px ${ ({ theme }) => theme.gray3 };
  background-color: white;
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  > svg {
    width: 20px;
    height: 20px;
    display: block;
    margin: 5px 0 22px 5px;
  }

  > strong {
    width: 100%;
    font-size: 1.2rem;
    color: ${ ({ theme }) => theme.gray };
    padding-left: 5px;
    display: block;
    text-align: left;

  }

  > span {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    left: 25px;
    border: 2px solid white;

    &.notification {
      background-color: ${ ({ theme }) => theme.invalid };
    }

    &.analisys {
      background-color: ${ ({ theme }) => theme.warning };
    }
  }
`

const ICONBUTTON = styled.button<IIconButtonSCProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  boxshadow: none;
  width: ${ ({ buttonSize }) => buttonSize }px;
  height: ${ ({ buttonSize }) => buttonSize }px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`

export {
  NAVBUTTON,
  ICONBUTTON
}