import styled from "styled-components";
import { CONTAINER, SUBTITLE } from "../../styles/base.styles";
import { mediaConfig } from "../../styles/themeConfig";

const REGISTER = styled.section`
  padding: 0 8px;

  &::before {
    content: "";
    height: 100%;
    width: 50vw;
    position: fixed;
    right: 0;
    top: 0;
    background: ${ ({ theme }) => theme.primary };

    ${ mediaConfig.tablet } {
      display: none;
    }
  }

  > ${ CONTAINER } {
    .register {
      &__content {
        ${ mediaConfig.desktop } {
          max-width: 384px;
        }

        > ${ SUBTITLE } {
          margin-bottom: 48px;
        }

        .input-group__input--checkbox {
          display: flex;
          justify-content: space-between;

          a {
            color: ${ ({ theme }) => theme.valid };
          }
        }
      }
    }
  }
`;

export {
  REGISTER,
}