import { Flip, toast } from "react-toastify";

// @ts-ignore
const notify = (type: PortaisCoreUI.ENotificationTypes, message: string) => {
  toast(message, {
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    position: "bottom-center",
    theme: "colored",
    transition: Flip,
    type
  })
}

export {
  notify as notifyUtil
}