declare global {
  interface Window {
    API_URL: string;
    SSO_CLIENT_ID: string;
    MOBBY_URL: string;
    MOBBY_DEV: boolean;
  }
}

const appSettings = {
  apiUrl: window?.API_URL,
  mobbyUrl: window?.MOBBY_URL,
};

const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.REACT_APP_ENV === "staging";
const isProd = process.env.REACT_APP_ENV === "production";
const isTest = process.env.NODE_ENV === "test";

export {
  appSettings as appSettingsUtil,
  isDev as isDevUtil,
  isStaging as isStagingUtil,
  isProd as isProdUtil,
  isTest as isTestUtil
};