const listenEvent = (name: any, callback: any, options?: AddEventListenerOptions) => {
  window.addEventListener(name, callback, options);
}

const emitEvent = (eventName: string, data: any) => {
  dispatchEvent(new CustomEvent(eventName, { detail: data }));
}

const removeEvent = (name: string, callback: any) => {
  window.removeEventListener(name, callback);
}

export {
  listenEvent as listenEventUtil,
  emitEvent as emitEventUtil,
  removeEvent as removeEventUtil
}