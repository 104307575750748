import React from "react";

export interface IStoreContext {
  setStore: React.Dispatch<IStoreAction>;
  store: IStoreData;
}

export interface IStoreData {
  isAuthenticated: boolean;
}

export interface IStoreProviderProps {
  children: React.ReactNode;
}

export interface IStoreAction {
  type: EStoreActionTypes;
  payload: any;
}

export interface IVehicle {
  licensePlate: string
  blocked: boolean
  phoneNumber: string
  limitTokenless: number
}

export interface IPhoneRegisterData {
  phoneNumber: string,
  verificationCode: string
}


export enum EStoreActionTypes {
  SET_STATE_BY_SESSION_BACKUP = "SET_STATE_BY_SESSION_BACKUP",
  SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED",
}

